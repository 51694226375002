import React, { JSX, useEffect, useState } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { getZones } from '../utils/api';
import { ZonesDataType } from '../utils/types';

type DropdownValue = string | number | boolean | (string | number | boolean)[] | undefined;

interface Zone {
  id: string;
  name: string;
  children: Zone[];
}

interface Option {
  text: string;
  value: string | undefined;
  content: JSX.Element;
}

interface DropdownZonesProps {
  placeholder?: string;
  multiple?: boolean;
  onChange: (value: DropdownValue) => void;
  dataType: ZonesDataType;
  error?: string;
}

const formatZonesValues: (zones: Zone[], n: number) => Option[] = (zones, n) => {
  return zones.reduce(
    (unfoldedZones: Option[], zone: Zone) =>
      unfoldedZones
        .concat([
          {
            text: zone.name,
            value: zone.id,
            content: (
              <span
                style={{
                  marginLeft: `${n}rem`,
                  fontWeight: zone.children && zone.children.length > 0 ? 600 : 'normal'
                }}>
                {zone.name}
              </span>
            )
          }
        ])
        .concat(formatZonesValues(zone.children, n + 1)),
    []
  );
};

const DropdownZones = ({ placeholder, multiple, onChange, dataType, error }: DropdownZonesProps) => {
  const [zones, setZones] = useState([]);
  useEffect(() => {
    getZones(dataType).then((res) => {
      setZones(res.data);
    });
  }, []);

  const options: Option[] = formatZonesValues(zones, 0);

  return (
    <Dropdown
      options={options as DropdownItemProps[]}
      placeholder={placeholder ? placeholder : 'Zone'}
      closeOnChange
      search
      error={!!error}
      fluid
      selection
      multiple={!!multiple}
      onChange={(_, { value }) => onChange(value)}
    />
  );
};

export default DropdownZones;
