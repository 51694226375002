import React, { useState } from 'react';
import { Form, Button, Select, Input } from 'semantic-ui-react';
import PhoneInput from 'react-phone-input-2';
import MyDropzone from './MyDropzone';
import { CampusFormData, CvTypeValues, CvTypesLabels, ZonesDataType } from '../utils/types';
import { checkErrors } from '../utils/functions';
import { uploadSourcing } from '../utils/api';
import DropdownZones from './DropdownZones';
import { useNavigate, useLocation } from 'react-router-dom';

const CampusForm = ({ sourceCampusId, isTest }: { sourceCampusId: string; isTest: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState<CampusFormData>({ mobility: [] });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [cvFile, setCvFile] = useState<File>();
  const [disabled, setDisabled] = useState(false);

  const updateFormData = (field: string, value?: string | number | boolean | (string | number | boolean)[]) => {
    setFormData({ ...formData, [field]: value });
    const newErrors = errors;
    delete newErrors[field];
    setErrors(newErrors);
  };

  const onChangeFormField =
    (field: string) =>
    (_: any, { value }: { value?: string | number | boolean | (string | number | boolean)[] }) => {
      updateFormData(field, value);
    };

  const validateForm = (form: CampusFormData, file?: File | undefined) => {
    setDisabled(true);
    const errors = checkErrors(form, file);

    if (Object.keys(errors).length < 1) {
      //construct filename (id-timestamp)
      const id = Math.random().toString(16).slice(2);
      const filename = id + '-' + Date.now();
      //init dateApplication
      form.dateApplication = new Date().getTime() / 1000 + '';
      //init sourceCampusId
      form.sourceCampusId = sourceCampusId;
      //is test?
      form.isTest = isTest;
      uploadSourcing(form, filename, file, () => {
        setDisabled(false);
        navigate(`/success${location.search}`);
      });
    } else {
      setErrors(errors);
      setDisabled(false);
    }
  };

  return (
    <div>
      <Form style={{ fontSize: '1.1em' }}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Civility</label>
            <Select
              required
              placeholder="Civility"
              options={[
                { text: 'Mrs', value: 'MRS' },
                { text: 'Mr', value: 'MR' }
              ]}
              onChange={onChangeFormField('civility')}
              error={!!errors?.civility}
            />
            <ErrorLabel error={errors?.civility} />
          </Form.Field>

          <Form.Field required error={!!errors?.firstName}>
            <label>Firstname</label>
            <Input placeholder="Firstname" onChange={onChangeFormField('firstName')} />
            <ErrorLabel error={errors?.firstName} />
          </Form.Field>
          <Form.Field required error={!!errors?.lastName}>
            <label>Lastname</label>
            <Input placeholder="Lastname" onChange={onChangeFormField('lastName')} />
            <ErrorLabel error={errors?.lastName} />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Contact Language</label>
            <Select
              required
              placeholder="Contact Language"
              options={[
                { text: 'FR', value: 'fr' },
                { text: 'NL', value: 'nl' },
                { text: 'UK', value: 'uk' }
              ]}
              onChange={onChangeFormField('contactLanguage')}
              error={!!errors?.contactLanguage}
            />
            <ErrorLabel error={errors?.contactLanguage} />
          </Form.Field>
          <Form.Field required error={!!errors?.email}>
            <label>Email</label>
            <Input placeholder="mymail@example.com" onChange={onChangeFormField('email')} />
            <ErrorLabel error={errors?.email} />
          </Form.Field>

          <Form.Field required error={errors?.mobilePhone}>
            <label>Phone</label>
            <PhoneInput
              preferredCountries={['be', 'fr', 'nl', 'de', 'lu']}
              country={'be'}
              onChange={(value) => updateFormData('mobilePhone', value)}
              inputStyle={{ paddingLeft: '50px', height: '100%' }}
            />
            <ErrorLabel error={errors?.mobilePhone} />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field required error={errors?.residency}>
            <label>Residency</label>
            <DropdownZones
              placeholder="Residency"
              dataType={ZonesDataType.RESIDENCY}
              onChange={(value) =>
                typeof value === 'string' ? updateFormData('residency', value) : updateFormData('residency', undefined)
              }
            />
            <ErrorLabel error={errors?.residency} />
          </Form.Field>
          <Form.Field required error={errors?.mobility}>
            <label>Mobilities</label>
            <DropdownZones
              placeholder="Mobilities"
              dataType={ZonesDataType.MOBILITY}
              multiple
              onChange={(value) =>
                Array.isArray(value)
                  ? updateFormData(
                      'mobility',
                      value.map((val) => val.toString())
                    )
                  : updateFormData('mobility', [])
              }
            />
            <ErrorLabel error={errors?.mobility} />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.TextArea label="Comment" onChange={onChangeFormField('comment')} placeholder="Anything else ?" />
        </Form.Group>
        <br />

        <Form.Field
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
          }}>
          {/* we use the value of the enum cvtypevalues to generate the list of cvs*/}
          {(Object.keys(CvTypeValues) as Array<keyof typeof CvTypeValues>).map((type) => (
            <Form.Checkbox
              key={type}
              label={CvTypesLabels[type]}
              value={type}
              checked={type === formData.cvType}
              onChange={onChangeFormField('cvType')}
            />
          ))}
        </Form.Field>
        <div style={{ textAlign: 'center' }}>
          <ErrorLabel error={errors?.cvType} />
        </div>
        {/* If NO_CV selected we don't show the dropzone since there is no cv to upload */}
        {formData.cvType !== CvTypeValues.NO_CV && (
          <Form.Field>
            <MyDropzone onChange={(file: File) => setCvFile(file)} />
          </Form.Field>
        )}
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size="big"
            style={{ backgroundColor: '#2ECC40', color: 'white' }}
            type="submit"
            disabled={disabled}
            onClick={() => validateForm(formData, cvFile)}>
            Validate
          </Button>
        </div>
      </Form>
    </div>
  );
};

const ErrorLabel = ({ error }: { error: string }) => {
  return <span style={{ color: '#c0392b', display: 'block', marginTop: '0.7em' }}>{error}</span>;
};

export default CampusForm;
