import { CampusFormData } from './types';

export const createJsonFile = (fileName: string, data: CampusFormData, cvFile?: File) => {
  const dataWithCV = cvFile ? { ...data, cvFileName: cvFile.name } : data;
  const file = new File([new Blob([JSON.stringify(dataWithCV)], { type: 'application/json' })], `${fileName}.json`);
  return file;
};

const entityMap: { [key: string]: string } = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;'
};
//tries to remove special characters from string and then compare it to the original
export const containsSpecialCharacters = (text: string | undefined) => {
  if (text) {
    const escapedString = String(text).replace(/[&<>"'`=/]/g, function (s) {
      return entityMap[s];
    });
    return escapedString != text;
  }
};

const getCorruptedFields = (form: CampusFormData, array: (string | undefined)[]) => {
  let corruptedFields = [];
  for (const field of array) {
    if (!!field && containsSpecialCharacters(form[field])) corruptedFields.push(field);
  }
  return corruptedFields;
};

const errorMapping: { [key: string]: string } = {
  firstName: 'Please enter a firstname',
  lastName: 'Please enter a lastname',
  email: 'Please enter an email',
  civility: 'Please select a civility',
  contactLanguage: 'Please select a contact language',
  mobilePhone: 'Please enter a phone number',
  cvType: 'Please select a CV language (or no CV)',
  residency: 'Please select a residency'
};

const checkField = (form: CampusFormData, field: string) => {
  return form[field] === undefined || form[field] === '' || form[field] === null;
};

export const checkErrors = (form: CampusFormData, file?: File | undefined) => {
  const errors: { [key: string]: string } = {};
  for (let key of Object.keys(errorMapping)) {
    if (checkField(form, key)) errors[key] = errorMapping[key];
  }
  if (form.cvType !== 'NO_CV' && file === undefined) {
    errors.cvType = 'Please upload your CV or select No CV';
  }
  if (form.mobility.length < 1) {
    errors.mobility = 'Please add at least one mobility';
  }
  const corruptedFields = getCorruptedFields(form, ['firstName', 'lastName', 'comment', 'email']);
  for (const field of corruptedFields) {
    errors[field] = 'We detected some forbidden characters ! Please remove them in your names, comment or email.';
  }
  return errors;
};
