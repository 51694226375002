enum CivilityValues {
  MRS = 'MRS',
  MR = 'MR'
}

enum ContactLanguageValues {
  FR = 'fr',
  NL = 'nl',
  UK = 'uk'
}

export enum CvTypeValues {
  NO_CV = 'NO_CV',
  CV_FR = 'CV_FR',
  CV_NL = 'CV_NL',
  CV_UK = 'CV_UK'
}

export enum ZonesDataType {
  MOBILITY = 'mobility',
  RESIDENCY = 'residency'
}

export const CvTypesLabels = {
  [CvTypeValues.NO_CV]: 'NO CV',
  [CvTypeValues.CV_FR]: 'CV FR',
  [CvTypeValues.CV_NL]: 'CV NL',
  [CvTypeValues.CV_UK]: 'CV UK'
};

export interface CampusFormData {
  isTest?: string;
  civility?: CivilityValues;
  firstName?: string;
  lastName?: string;
  contactLanguage?: ContactLanguageValues;
  email?: string;
  mobilePhone?: string;
  comment?: string;
  cvType?: CvTypeValues;
  dateApplication?: string;
  sourceCampusId?: string;
  residency?: string;
  mobility: string[];
  [key: string]: any; //TypeScript will understand that any string can be used to index an object of type campusFormData
}
