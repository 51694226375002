import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface MyDropzoneProps {
  onChange: (file: File) => void;
}

const MyDropzone = ({ onChange }: MyDropzoneProps) => {
  const [file, setFile] = useState<File | null>(null);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      onChange(acceptedFiles[0]);
      setFile(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '4em',
          padding: '2em',
          background: '#F9F9F9',
          cursor: 'pointer',
          borderRadius: '4px',
          border: 'solid 1px #DEDEDF',
          textAlign: 'center'
        }}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop your CV here ...</p> : <p>{'Drop your CV here'}</p>}
      </div>
      {!!file && (
        <p>
          Current CV file: <strong>{file.name}</strong>
        </p>
      )}
    </>
  );
};

export default MyDropzone;
