import axios from 'axios';
import { createJsonFile } from './functions';
import { CampusFormData, ZonesDataType } from './types';

// TODO: put this in an .env file
//const API_URL = 'http://localhost:8081';
const API_URL = 'https://sourcing.creamconsulting.com';

export const uploadSourcing = (data: CampusFormData, fileName: string, cvFile?: File, onSuccess?: () => void) => {
  const formData = new FormData();
  if (cvFile) {
    //extract extension of file
    const ext = cvFile.name.split('.')[1];
    //change name of cv to match filename
    //create new file with new name
    const newFile = new File([cvFile], fileName + '.' + ext, {
      type: cvFile.type
    });
    formData.append('profile', createJsonFile(fileName, data, newFile));
    formData.append('cv', newFile);
  } else {
    formData.append('profile', createJsonFile(fileName, data));
  }
  formData.append('data', JSON.stringify({ ...data }));
  const url = data.isTest === 'true' ? `${API_URL}/upload-sourcing-campus-test` : `${API_URL}/upload-sourcing-campus`;
  axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(() => {
      // handle the response
      if (onSuccess) onSuccess();
    })
    .catch((error) => {
      // handle errors
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

export const getZones = async (zoneType: ZonesDataType) => {
  return axios.get(`${API_URL}/zones?zoneType=${zoneType}`);
};
