import React from 'react';
import { Header, Container, Grid, Divider, Button } from 'semantic-ui-react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import logo from './images/logoWithTitle.png';
import CampusForm from './components/CampusForm';

const App: React.FC = () => {
  return (
    <Router>
      <CampusLayout>
        <Routes>
          <Route path="/" Component={RestrictedRoute} />
          <Route path="/success" Component={SuccessRoute} />
        </Routes>
      </CampusLayout>
    </Router>
  );
};

const RestrictedRoute: React.FC = () => {
  const location = useLocation();

  // Get the value of the parameter from the URL
  const sourceCampusParam = new URLSearchParams(location.search).get('sourceCampus');
  const isTestParam = new URLSearchParams(location.search).get('isTest');

  // Check if the parameter is not null
  if (sourceCampusParam === null) {
    // Redirect and display an error message
    return <NotFound />;
  }

  // Return the component for the restricted route when sourceCampusId is present
  return <RestrictedComponent sourceCampusId={sourceCampusParam} isTest={isTestParam === null ? 'false' : 'true'} />;
};

const SuccessRoute: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '2em'
      }}>
      <Header as="h2" style={{ color: '#2ECC40', margin: '2em' }}>
        Your form has been successfully submitted. Thank you!
      </Header>
      <Button
        size="big"
        style={{ backgroundColor: '#2ECC40', color: 'white', margin: '2em' }}
        onClick={() => navigate(`/${location.search}`)}>
        Back to form
      </Button>
    </div>
  );
};

// NotFound component: used when navigating to path without sourceCampusId
const NotFound: React.FC = () => {
  return <div>Page not found</div>;
};

interface RestrictedComponentProps {
  sourceCampusId: string;
  isTest: string;
}

const RestrictedComponent: React.FC<RestrictedComponentProps> = ({ sourceCampusId, isTest }) => {
  return <CampusForm sourceCampusId={sourceCampusId} isTest={isTest} />;
};

type CampusLayoutProps = {
  children: React.ReactNode;
};

const CampusLayout: React.FC<CampusLayoutProps> = ({ children }) => {
  return (
    <Container fluid>
      <Grid centered>
        <Grid.Row>
          <div
            style={{
              borderRadius: 0,
              marginBottom: 0,
              display: 'flex',
              width: '100%',
              height: '100%',
              padding: '1em',
              paddingLeft: '2%',
              alignItems: 'center',
              backgroundColor: '#2ECC40'
            }}>
            <a target="_blank" href="https://www.creamconsulting.com/" rel="noreferrer">
              <img src={logo} alt="Cream Consulting" width="150px" />
            </a>
          </div>
        </Grid.Row>
        <Grid.Row>
          <Header as="h1" style={{ color: 'rgb(104, 104, 104)' }}>
            CONTACT FORM
          </Header>
        </Grid.Row>

        <Divider style={{ backgroundColor: '#2ECC40', marginLeft: '40%', marginRight: '40%', height: '0.2em' }} />

        <Grid.Row>
          <Grid.Column width={14}>{children} </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default App;
